import { Typography } from '@mui/material';
import React from 'react';

const Version: React.FC = () => (
  <Typography
    component="p"
    variant="body2"
    sx={{
      fontSize: 13,
      position: 'absolute',
      right: '20px',
      bottom: '15px',
    }}
    data-qa="Version 4.0.4"
  >
    Version 4.0.4
  </Typography>
);

export default Version;
