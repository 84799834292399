import lodashOrderBy from 'lodash/orderBy';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useMemo, useState, useRef } from 'react';
import {
  Button,
  Container,
  TableContainer,
  Table,
  TableBody,
  Box,
  TablePagination,
  FormControlLabel,
  Switch,
  DialogActions,
  Typography,
} from '@mui/material';

import { formatDistance, subDays } from 'date-fns';

import useSettings from '../../../shared/hooks/useSettings';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { EstateObject, EstateObjectsResponse } from '../../../shared/@types/blog';
import Page from '../../../shared/components/Page';
import Iconify from '../../../shared/components/Iconify';
import HeaderBreadcrumbs from '../../../shared/components/HeaderBreadcrumbs';
import useFetch from '../../../shared/hooks/useFetch';
import { HOST_API } from '../../../config';
import { API, API_ENDPOINTS } from '../../../shared/utils/api';
import Scrollbar from '../../../shared/components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData } from '../../../shared/components/table';
import useTable, { emptyRows } from '../../../shared/hooks/useTable';
import RealEstateTableRow from './sections/list/RealEstateTableRow';
import { DialogAnimate } from '../../../shared/components/animate';
import { useSortableData } from 'src/shared/hooks/useSortableData';

const TABLE_HEAD = [
  { id: 'images', label: 'Image', align: 'left', sortable: false },
  { id: 'id', label: 'Id', align: 'left', sortable: true },
  { id: 'title', label: 'Title', align: 'left', sortable: true },
  { id: 'isPublished', label: 'Is Published', align: 'left', sortable: true },
  { id: 'price', label: 'Price', align: 'left', sortable: true  },
  { id: 'createdAt', label: 'Created At', align: 'left', sortable: true  },
  { id: 'realtor', label: 'Realtor', align: 'left', sortable: true  },
  { id: '' },
];

export default function RealEstateList() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const deleteId = useRef('');
  const { data, refreshFetch } = useFetch<EstateObjectsResponse>(
    HOST_API + API_ENDPOINTS.ALL_REAL_ESTATE
  );

  const tableData = useMemo(() => {
    if (!data?.list) return [];

    return data.list.map((i) => {
      return {
        ...i,
        createdAt: formatDistance(subDays(new Date(i.createdAt), 0), new Date(), {
          addSuffix: true,
        }),
      };
    });
  }, [data]);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const sortedData = useSortableData<EstateObject>(tableData, orderBy, order);

  const onConfirmDelete = () => {
    setIsOpenModalDelete(false);
    API.delete<number>(API_ENDPOINTS.DELETE_REAL_ESTATE.replace(':id', deleteId.current)).then(
      () => {
        deleteId.current = '';
        refreshFetch();
      }
    );
  };

  const handleDeleteRow = (id: string) => {
    deleteId.current = id;
    setIsOpenModalDelete(true);
  };

  const denseHeight = dense ? 52 : 72;

  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.realEstate.edit(id));
  };

  const isNotFound = !sortedData.length;

  const onPublish = (data: EstateObject) => {
    const formData = new FormData();

    Object.keys(data).forEach((formProp) => {
      if (formProp === 'user_role') return;
      // @ts-ignore
      if (formProp === 'avatarUrl' && data[formProp]?.name) {
        // @ts-ignore
        formData.append('files', data[formProp], data[formProp].name);
      } else {
        // @ts-ignore
        formData.append(formProp, data[formProp]);
      }
    });

    console.log('onPublish -> data', data);

    API.put<any>(API_ENDPOINTS.UPDATE_REAL_ESTATE.replace(':id', data.id), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then(() => {
      refreshFetch();
    });
  };

  const onCloseDelete = () => {
    setIsOpenModalDelete(false);
  };

  return (
    <Page title="Estate Objects" position="relative">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Featured Listings"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Featured Listings', href: PATH_DASHBOARD.realEstate.root },
            { name: 'Items' },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.realEstate.new}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              New Real Estate
            </Button>
          }
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSort={onSort}
                onSelectAllRows={(checked) => {
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row?.id)
                  );
                }}
              />

              <TableBody>
                {sortedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <RealEstateTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      onPublish={(isPublished) => onPublish({ ...row, isPublished })}
                    />
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                />

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />

          <FormControlLabel
            control={<Switch checked={dense} onChange={onChangeDense} />}
            label="Dense"
            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
          />
        </Box>
      </Container>

      <DialogAnimate open={isOpenModalDelete} onClose={onCloseDelete}>
        <Box sx={{ pt: 2, pl: 3, pr: 3 }}>Are you confirm delete item</Box>,
        <DialogActions sx={{ px: 3 }}>
          <Button color="error" onClick={onConfirmDelete}>
            Yes
          </Button>
          <Button onClick={onCloseDelete}>Cancel</Button>
        </DialogActions>
      </DialogAnimate>
    </Page>
  );
}
