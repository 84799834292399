import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material';

interface RealtorWelcomeDialogProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

const RealtorWelcomeDialog: React.FC<RealtorWelcomeDialogProps> = ({ isOpen, onClose }) => {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>Realtor Welcome</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Thank you for registration with DelekHomes. Our realtor verification team will reach out
          to you shortly over the email.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RealtorWelcomeDialog;
