import * as React from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Iconify from '../../../../shared/components/Iconify';
import { Grid, Box } from '@mui/material';
import { FormProvider, RHFTextField } from '../../../../shared/components/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { API, API_ENDPOINTS } from '../../../../shared/utils/api';
import { useSnackbar } from 'notistack';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minWidth: '100%',
    maxWidth: '500px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

type FormValuesProps = {
  property: string;
  name: string;
  email: string;
  phone: number | string;
  message: string;
};

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify
            icon={'ep:circle-close-filled'}
            width={20}
            height={20}
            sx={{ cursor: 'pointer' }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function RealtorSendMessageDialog() {
  const [open, setOpen] = React.useState(false);
  const { id: estateObjectId } = useParams();
  const {enqueueSnackbar} = useSnackbar()

  const MakeAnInquirySchema = Yup.object().shape({
    property: Yup.string(),
    name: Yup.string(),
    email: Yup.string(),
    phone: Yup.string(),
    message: Yup.string(),
  });

  const defaultValues = {
    estateObjectId,
    property: '',
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(MakeAnInquirySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: FormValuesProps) => {
    if (data) {
      await API.post<any>(API_ENDPOINTS.ADD_INQUIRY, data);
      enqueueSnackbar('Inquiry maked successfully!');
    }
  };

  return (
    <div>
      <Box sx={{ pt: 2 }}>
        <Button variant="contained" onClick={handleClickOpen}>
          Make An Inquiry
        </Button>
      </Box>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Make An Inquiry
        </BootstrapDialogTitle>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <RHFTextField fullWidth label="Property" name="property" />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField fullWidth label="Name" name="name" />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField fullWidth label="Email" name="email" />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField fullWidth label="Phone" name="phone" />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField fullWidth label="Message" name="message" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              onClick={handleClose}
            >
              Save Changes
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </BootstrapDialog>
    </div>
  );
}
